<template>
  <v-navigation-drawer
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    class="draw-bg"
  >
   
    <v-divider class="mb-1" />

    <v-list shaped>
      <v-list-item class="">
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="ovec.png" rounded />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>ID: {{ user.user_name }}</v-list-item-title>
          <v-list-item-title>{{ user.user_firstname }} </v-list-item-title>
          <v-list-item-title>{{ user.user_lastname }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />
    <v-list-item to="/supervisions/Dashboard">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>

      <v-list-item-title>หน้าแรก</v-list-item-title>
    </v-list-item>

    <v-list-item to="/supervisions/info">
      <v-list-item-icon>
        <v-icon>mdi-information-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-title>ข้อมูลทั่วไป</v-list-item-title>
    </v-list-item>

    <v-list-item to="/supervisions/personnel_supervision">
      <v-list-item-icon>
        <v-icon>mdi-account-arrow-right</v-icon>
      </v-list-item-icon>

      <v-list-item-title>ข้อมูลศึกษานิเทศก์</v-list-item-title>
    </v-list-item>

    <!-- 
    <v-list-item to="/supervisions/personnel">
      <v-list-item-icon>
        <v-icon>mdi-account-arrow-right</v-icon>
      </v-list-item-icon>

      <v-list-item-title>ข้อมูลผู้ใช้งานระบบ</v-list-item-title>
    </v-list-item> -->

    <v-list>
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :to="child.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="child.action"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list> </v-list>
    <v-card>
      <div align="center">
        <v-img width="120" src="/HRvecfiles/ipa2.jpg"></v-img>
        <v-card-title class="text-center">
          Line Group : กจ2
        </v-card-title>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboarduserDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    user_data: {},
    user: {},
    loginuser: JSON.parse(sessionStorage.getItem("user")) || 0,
    items: [
      {
        action: "mdi-account-reactivate",
        items: [
          {
            title: "ข้อมูลคุณวุฒิการศึกษา",
            action: "mdi-school",
            to: "/supervisions/personnel_education"
          },
          {
            title: "ข้อมูลประสบการณ์",
            action: "mdi-airplane",
            to: "/supervisions/personnel_experience"
          },
          {
            title: "ข้อมูลประวัติการรับราชการ",
            action: "mdi-access-point-network",
            to: "/supervisions/personnel_work_history"
          },
          {
            title: "ข้อมูลผลงาน รางวัล",
            action: "mdi-trophy-award",
            to: "/supervisions/personnel_award"
          },
          {
            title: "ข้อมูลโทษ วินัย",
            action: "mdi-alarm-light",
            to: "/supervisions/personnel_discipline"
          }
        ],
        title: "ระบบงานบุคคล",
        system_config:[],

      }
    ]
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    computedPersonnels() {
      return this.personnels.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: ""
      };
    }
  },
  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("user.php", {
      user_ID: userSession.user_ID,
      ApiKey: "HRvec2021"
    });
    this.user = result.data;
    await this.system_configQuery();
    await this.showLocaleTime();
  },

  methods: {
    async showLocaleTime() {
      setInterval(async () => {
        await this.system_configQuery();
      }, 10000);
    },
    
    async system_configQuery() {
      let result = await this.$http.post("system_config.php", {
        ApiKey: "HRvec2021"
      });
      this.system_config = result.data;
      let data = this.system_config;  
      data.forEach(value => {
        if (
          value.system_config_fig == this.user.region_ID &&
          value.system_config_status == "1"
        ) {
          let date = this.formatDateToThaiIntl(value.system_config_stop);
          let text =
            value.system_config_topic + " สิ้นสุดการปิดระบบวันที่ <br>" + date;
          Swal.fire({
            icon: "error",
            title: text,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(async result => {
            if (result.isConfirmed) {
              await this.logout()              
            }
          });
        } else if (
          value.system_config_fig == this.user.user_status &&
          value.system_config_status == "1"
        ) {
          let date = this.formatDateToThaiIntl(value.system_config_stop);
          let text =
            value.system_config_topic + " สิ้นสุดการปิดระบบวันที่ <br>" + date;
          Swal.fire({
            icon: "error",
            title: text,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(async result => {
            if (result.isConfirmed) {
              await this.logout()   
            }
          });
        } else if (
          value.system_config_fig == "all" &&
          value.system_config_status == "1"
        ) {
          let date = this.formatDateToThaiIntl(value.system_config_stop);
          let text =
            value.system_config_topic + " สิ้นสุดการปิดระบบวันที่ <br>" + date;
          Swal.fire({
            icon: "error",
            title: text,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(async result => {
            if (result.isConfirmed) {
              await this.logout()   
            }
          });
        }
      });
    },

    formatDateToThaiIntl(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "Asia/Bangkok"
      };

      return new Intl.DateTimeFormat("th-TH", options).format(date);
    },

    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style>
.draw-bg {
  background-color: #787777;
  border-color: #ffffff;
}
</style>
